import * as React from 'react'
import { Link } from 'gatsby'
import { useAppSelector } from '../../redux/hooks'

import './data.scss'

const DataPage = () => {
	const primary = useAppSelector((state) => state.UserReducer.primaryAccount)

	return (
		<main>
			<div className='data'>
				{primary}
				<h2>DataPage</h2>
				<Link to='/'>Home</Link>
			</div>
		</main>
	)
}

export default DataPage
